// JsFromRoutes CacheKey 35a475b93fd1a1d603b37a9b9237feb3
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  chat: definePathHelper('post', '/ai/chat'),
  action: definePathHelper('get', '/ai/action'),
  clear: definePathHelper('post', '/ai/clear'),
  reviewXlsx: definePathHelper('post', '/ai/review_xlsx')
}
